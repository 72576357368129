* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
}

.app {
  background-color: #f1f2f5;
  /* max-height: 160vh;
  overflow: hidden; */
  overflow-x: hidden;
  min-height: 100vh;
  font-family: "Lato", sans-serif;
}

.highcharts-credits {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.crypto-market > div {
  height: 100%;
}

@media only screen and (min-width: 1280px) {
  .crypto-market > div {
    height: 100%;
  }
}